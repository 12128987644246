export const TIMEZONE_OFFSET = '+03:00'

export const PAGINATION_LIMIT = 200
export const TASK_PAGINATION_LIMIT = 15
export const BRANDS_PAGINATION_LIMIT = 10
export const CDN_STATS_LIMIT = 5

export const S3_MAX_KEYS_LIMIT = 200
export const S3_POLICY_VERSION = '2012-10-17'
export const s3endpointsConf = {
  Platformcraft: 'eu-s3.platformcraft.com',
  'Beeline Cloud': 'eu-s3.beelinecloud.ru',
  DataRu: 's3.cloud.dataru.ru'
}

export const timezones = [
  {
    value: 'Hawaiian (-10)',
    abbr: 'HST'
  },
  {
    value: 'Alaskan (-8)',
    abbr: 'AKDT'
  },
  {
    value: 'Pacific (-7)',
    abbr: 'PDT'
  },
  {
    value: 'Pacific (-8)',
    abbr: 'PST'
  },
  {
    value: 'US Mountain (-7)',
    abbr: 'UMST'
  },
  {
    value: 'Mountain (Mexico) (-6)',
    abbr: 'MDT'
  },
  {
    value: 'Central America (-6)',
    abbr: 'CAST'
  },
  {
    value: 'Central (Mexico) (-5)',
    abbr: 'CDT'
  },
  {
    value: 'Canada Central (-6)',
    abbr: 'CCST'
  },
  {
    value: 'SA Pacific (-5)',
    abbr: 'SPST'
  },
  {
    value: 'Eastern (-4)',
    abbr: 'EDT'
  },
  {
    value: 'US Eastern (-4)',
    abbr: 'UEDT'
  },
  {
    value: 'Venezuela (-4.5)',
    abbr: 'VST'
  },
  {
    value: 'Paraguay (-4)',
    abbr: 'PYT'
  },
  {
    value: 'Atlantic (-3)',
    abbr: 'ADT'
  },
  {
    value: 'Central Brazilian (-4)',
    abbr: 'CBST'
  },
  {
    value: 'SA Western (-4)',
    abbr: 'SWST'
  },
  {
    value: 'Pacific SA (-4)',
    abbr: 'PSST'
  },
  {
    value: 'Newfoundland (-2.5)',
    abbr: 'NDT'
  },
  {
    value: 'E. South America (-3)',
    abbr: 'ESAST'
  },
  {
    value: 'Argentina (-3)',
    abbr: 'AST'
  },
  {
    value: 'SA Eastern (-3)',
    abbr: 'SEST'
  },
  {
    value: 'Greenland (-3)',
    abbr: 'GDT'
  },
  {
    value: 'Montevideo (-3)',
    abbr: 'MST'
  },
  {
    value: 'Bahia (-3)',
    abbr: 'BST'
  },
  {
    value: 'UTC-02 (-2)',
    abbr: 'U'
  },
  {
    value: 'Mid-Atlantic (-1)',
    abbr: 'MDT'
  },
  {
    value: 'Azores (0)',
    abbr: 'ADT'
  },
  {
    value: 'Cape Verde (-1)',
    abbr: 'CVST'
  },
  {
    value: 'Morocco (+1)',
    abbr: 'MDT'
  },
  {
    value: 'UTC (0)',
    abbr: 'UTC'
  },
  {
    value: 'GMT (0)',
    abbr: 'GMT'
  },
  {
    value: 'British Summer Time (+1)',
    abbr: 'BST'
  },
  {
    value: 'GMT (+1)',
    abbr: 'GDT'
  },
  {
    value: 'Greenwich (0)',
    abbr: 'GST'
  },
  {
    value: 'W. Europe (+2)',
    abbr: 'WEDT'
  },
  {
    value: 'Central Europe (+2)',
    abbr: 'CEDT'
  },
  {
    value: 'Romance (+2)',
    abbr: 'RDT'
  },
  {
    value: 'Central European (+2)',
    abbr: 'CEDT'
  },
  {
    value: 'W. Central Africa (+1)',
    abbr: 'WCAST'
  },
  {
    value: 'Namibia (+1)',
    abbr: 'NST'
  },
  {
    value: 'GTB (+3)',
    abbr: 'GDT'
  },
  {
    value: 'Middle East (+3)',
    abbr: 'MEDT'
  },
  {
    value: 'Egypt (+2)',
    abbr: 'EST'
  },
  {
    value: 'Syria (+3)',
    abbr: 'SDT'
  },
  {
    value: 'E. Europe (+3)',
    abbr: 'EEDT'
  },
  {
    value: 'South Africa (+2)',
    abbr: 'SAST'
  },
  {
    value: 'FLE (+3)',
    abbr: 'FDT'
  },
  {
    value: 'Turkey (+3)',
    abbr: 'TDT'
  },
  {
    value: 'Israel (+3)',
    abbr: 'JDT'
  },
  {
    value: 'Libya (+2)',
    abbr: 'LST'
  },
  {
    value: 'Jordan (+3)',
    abbr: 'JST'
  },
  {
    value: 'Arabic (+3)',
    abbr: 'AST'
  },
  {
    value: 'Kaliningrad (+3)',
    abbr: 'KST'
  },
  {
    value: 'Arab (+3)',
    abbr: 'AST'
  },
  {
    value: 'E. Africa (+3)',
    abbr: 'EAST'
  },
  {
    value: 'Moscow (+3)',
    abbr: 'MSK'
  },
  {
    value: 'Samara Time (+4)',
    abbr: 'SAMT'
  },
  {
    value: 'Iran (+4.5)',
    abbr: 'IDT'
  },
  {
    value: 'Arabian (+4)',
    abbr: 'AST'
  },
  {
    value: 'Azerbaijan (+5)',
    abbr: 'ADT'
  },
  {
    value: 'Mauritius (+4)',
    abbr: 'MST'
  },
  {
    value: 'Georgian (+4)',
    abbr: 'GET'
  },
  {
    value: 'Caucasus (+4)',
    abbr: 'CST'
  },
  {
    value: 'Afghanistan (+4.5)',
    abbr: 'AST'
  },
  {
    value: 'West Asia (+5)',
    abbr: 'WAST'
  },
  {
    value: 'Yekaterinburg (+5)',
    abbr: 'YEKT'
  },
  {
    value: 'Pakistan (+5)',
    abbr: 'PKT'
  },
  {
    value: 'India (+5.5)',
    abbr: 'IST'
  },
  {
    value: 'Sri Lanka (+5.5)',
    abbr: 'SLST'
  },
  {
    value: 'Nepal (+5.75)',
    abbr: 'NST'
  },
  {
    value: 'Central Asia (+6)',
    abbr: 'CAST'
  },
  {
    value: 'Bangladesh (+6)',
    abbr: 'BST'
  },
  {
    value: 'Myanmar (+6.5)',
    abbr: 'MST'
  },
  {
    value: 'SE Asia (+7)',
    abbr: 'SAST'
  },
  {
    value: 'N. Central Asia (+7)',
    abbr: 'NCAST'
  },
  {
    value: 'China (+8)',
    abbr: 'CST'
  },
  {
    value: 'North Asia (+8)',
    abbr: 'NAST'
  },
  {
    value: 'Singapore (+8)',
    abbr: 'MPST'
  },
  {
    value: 'W. Australia (+8)',
    abbr: 'WAST'
  },
  {
    value: 'Taipei (+8)',
    abbr: 'TST'
  },
  {
    value: 'Ulaanbaatar (+8)',
    abbr: 'UST'
  },
  {
    value: 'North Asia East (+8)',
    abbr: 'NAEST'
  },
  {
    value: 'Japan (+9)',
    abbr: 'JST'
  },
  {
    value: 'Korea (+9)',
    abbr: 'KST'
  },
  {
    value: 'Cen. Australia (+9.5)',
    abbr: 'CAST'
  },
  {
    value: 'AUS Central (+9.5)',
    abbr: 'ACST'
  },
  {
    value: 'E. Australia (+10)',
    abbr: 'EAST'
  },
  {
    value: 'AUS Eastern (+10)',
    abbr: 'AEST'
  },
  {
    value: 'West Pacific (+10)',
    abbr: 'WPST'
  },
  {
    value: 'Tasmania (+10)',
    abbr: 'TST'
  },
  {
    value: 'Yakutsk (+9)',
    abbr: 'YST'
  },
  {
    value: 'Central Pacific (+11)',
    abbr: 'CPST'
  },
  {
    value: 'Vladivostok (+11)',
    abbr: 'VST'
  },
  {
    value: 'New Zealand (+12)',
    abbr: 'NZST'
  },
  {
    value: 'UTC+12 (+12)',
    abbr: 'U'
  },
  {
    value: 'Fiji (+12)',
    abbr: 'FST'
  },
  {
    value: 'Magadan (+12)',
    abbr: 'MST'
  },
  {
    value: 'Kamchatka (+13)',
    abbr: 'KDT'
  },
  {
    value: 'Tonga (+13)',
    abbr: 'TST'
  },
  {
    value: 'Samoa (+13)',
    abbr: 'SST'
  }
]

export const s3ActionsOptions = [
  { value: 's3:*', label: '*' },
  { value: 's3:AbortMultipartUpload', label: 'AbortMultipartUpload' },
  { value: 's3:CopyObject', label: 'CopyObject' },
  { value: 's3:CreateBucket', label: 'CreateBucket' },
  { value: 's3:CreateMultipartUpload', label: 'CreateMultipartUpload' },
  { value: 's3:DeleteBucket', label: 'DeleteBucket' },
  { value: 's3:DeleteBucketAnalyticsConfiguration', label: 'DeleteBucketAnalyticsConfiguration' },
  { value: 's3:DeleteBucketCors', label: 'DeleteBucketCors' },
  { value: 's3:DeleteBucketEncryption', label: 'DeleteBucketEncryption' },
  { value: 's3:DeleteBucketInventoryConfiguration', label: 'DeleteBucketInventoryConfiguration' },
  { value: 's3:DeleteBucketLifecycle', label: 'DeleteBucketLifecycle' },
  { value: 's3:DeleteBucketMetricsConfiguration', label: 'DeleteBucketMetricsConfiguration' },
  { value: 's3:DeleteBucketPolicy', label: 'DeleteBucketPolicy' },
  { value: 's3:DeleteBucketReplication', label: 'DeleteBucketReplication' },
  { value: 's3:DeleteBucketTagging', label: 'DeleteBucketTagging' },
  { value: 's3:DeleteBucketWebsite', label: 'DeleteBucketWebsite' },
  { value: 's3:DeleteObject', label: 'DeleteObject' },
  { value: 's3:DeleteObjectTagging', label: 'DeleteObjectTagging' },
  { value: 's3:DeleteObjects', label: 'DeleteObjects' },
  { value: 's3:DeletePublicAccessBlock', label: 'DeletePublicAccessBlock' },
  { value: 's3:GetBucketAccelerateConfiguration', label: 'GetBucketAccelerateConfiguration' },
  { value: 's3:GetBucketAcl', label: 'GetBucketAcl' },
  { value: 's3:GetBucketAnalyticsConfiguration', label: 'GetBucketAnalyticsConfiguration' },
  { value: 's3:GetBucketCors', label: 'GetBucketCors' },
  { value: 's3:GetBucketEncryption', label: 'GetBucketEncryption' },
  { value: 's3:GetBucketInventoryConfiguration', label: 'GetBucketInventoryConfiguration' },
  { value: 's3:GetBucketLifecycleConfiguration', label: 'GetBucketLifecycleConfiguration' },
  { value: 's3:GetBucketLocation', label: 'GetBucketLocation' },
  { value: 's3:GetBucketLogging', label: 'GetBucketLogging' },
  { value: 's3:GetBucketMetricsConfiguration', label: 'GetBucketMetricsConfiguration' },
  { value: 's3:GetBucketNotification', label: 'GetBucketNotification' },
  { value: 's3:GetBucketPolicy', label: 'GetBucketPolicy' },
  { value: 's3:GetBucketPolicyStatus', label: 'GetBucketPolicyStatus' },
  { value: 's3:GetBucketReplication', label: 'GetBucketReplication' },
  { value: 's3:GetBucketRequestPayment', label: 'GetBucketRequestPayment' },
  { value: 's3:GetBucketTagging', label: 'GetBucketTagging' },
  { value: 's3:GetBucketVersioning', label: 'GetBucketVersioning' },
  { value: 's3:GetBucketWebsite', label: 'GetBucketWebsite' },
  { value: 's3:GetObject', label: 'GetObject' },
  { value: 's3:GetObjectAcl', label: 'GetObjectAcl' },
  { value: 's3:GetObjectAttributes', label: 'GetObjectAttributes' },
  { value: 's3:HeadBucket', label: 'HeadBucket' },
  { value: 's3:HeadObject', label: 'HeadObject' },
  { value: 's3:ListAllMyBuckets', label: 'ListAllMyBuckets' },
  { value: 's3:ListBuckets', label: 'ListBuckets' },
  { value: 's3:ListMultipartUploads', label: 'ListMultipartUploads' },
  { value: 's3:ListBucketMultipartUploads', label: 'ListBucketMultipartUploads' },
  { value: 's3:ListObjectVersions', label: 'ListObjectVersions' },
  { value: 's3:ListObjects', label: 'ListObjects' },
  { value: 's3:ListObjectsV2', label: 'ListObjectsV2' },
  { value: 's3:PutBucketAcl', label: 'PutBucketAcl' },
  { value: 's3:PutBucketCors', label: 'PutBucketCors' },
  { value: 's3:PutBucketLifecycleConfiguration', label: 'PutBucketLifecycleConfiguration' },
  { value: 's3:PutBucketNotification', label: 'PutBucketNotification' },
  { value: 's3:PutBucketPolicy', label: 'PutBucketPolicy' },
  { value: 's3:PutBucketReplication', label: 'PutBucketReplication' },
  { value: 's3:PutBucketVersioning', label: 'PutBucketVersioning' },
  { value: 's3:PutBucketWebsite', label: 'PutBucketWebsite' },
  { value: 's3:PutObject', label: 'PutObject' }
]
